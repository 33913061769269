.navbar-container {
    background-color: unset;
    height: 10vh;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem;
    position: absolute;
    z-index: 1;
    -webkit-backdrop-filter: blur(1.2px);
    backdrop-filter: blur(1.3px);
}
    .navbar-container img {
        height: 6rem;
        cursor: pointer;
        transition: transform .2s;
        filter: brightness(1000);
    }
    .navbar-container img:hover {
        transform: scale(1.1);
    }

.navbar-buttons *{
    margin-left: 1rem;
}
/* 
.navbar-ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
    .navbar-ul > a {
        text-decoration: none;
        color: white;
    } */